<template>
  <van-nav-bar
    left-text="返回"
    right-text="删除"
    left-arrow
    fixed
    placeholder
    @click-left="onGoBack"
    @click-right="onDelete"
  />
  <template v-if="items.length > 0">
    <template v-for="store in items" :key="store">
      <van-checkbox-group v-model="cartIds">
        <div style="margin:10px;background:#fff;">
          <van-cell :title="store.storeName" icon="shop-o" value="逛逛" is-link />
          <template v-for="item in store.products" :key="item">
            <div style="padding:10px;">
              <van-row gutter="10">
                <van-col span="2"><!-- :disabled="item.errorCode !== 0" -->
                  <van-checkbox :name="item.cartId" checked-color="#f44" style="margin:25px 0 0 0;"></van-checkbox>
                </van-col>
                <van-col span="7">
                  <van-image :src="item.image + '?imageView2/1/w/400/h/400/q/100'"/>
                </van-col>
                <van-col span="15">
                  <p>
                    <small>{{ item.name }}</small>
                  </p>
                  <div v-if="item.skuText !== ''" style="margin:5px 0 0 0;">
                    <van-tag type="default">{{ item.skuText }}</van-tag>
                  </div>
                  <div v-if="item.bookingDate" style="margin:5px 0 0 0;">
                    <van-tag type="default">{{ item.bookingDate }}</van-tag>
                  </div>
                  <div v-if="item.errorCode !== 0" style="margin:5px 0 0 0;">
                    <van-tag type="danger">{{ item.errorMessage }}</van-tag>
                  </div>
                  <div style="margin:10px 0 0 0;">
                    <h5 style="display:inline;color:#f44;">&yen;</h5>
                    <h3 style="display:inline;color:#f44;">{{ item.price }}</h3>
                    <van-stepper v-model="item.quantity" min="1" max="99" style="float:right;" />
                  </div>
                </van-col>
              </van-row>
            </div>
          </template>
        </div>
      </van-checkbox-group>
    </template>
  </template>
  <div style="height:100px;"></div>
  <van-submit-bar
    :price="total * 100"
    tip-icon="info-o"
    button-text="结算"
    @submit="onSubmit"
  />
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch } from 'vue'
import { Toast } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const state = reactive({
      value: 0,
      total: 0,
      checked: false,
      cartIds: [],
      storeId: Cookies.get('storeId'),
      buyerId: Cookies.get('buyerId'),
      products: [],
      stores: [],
      items: []
    })
    const onSubmit = () => {
      if (state.cartIds.length > 0) {
        let selected = []
        for (const v of state.items) {
          selected = v.products.filter(v => state.cartIds.includes(v.cartId))
        }
        const products = []
        for (const v of selected) {
          products.push({
            productId: v.productId,
            quantity: v.quantity,
            skuId: v.skuId,
            skuDayId: v.skuDayId
          })
        }
        // console.log(state.cartIds)
        // console.log(selected)
        console.log(products)
        useRouter.push({
          name: 'OrderForm',
          query: {
            products: JSON.stringify(products)
          }
        })
      }
    }
    const onGoBack = () => {
      useRouter.go(-1)
    }
    const resetTotal = () => {
      state.total = 0
      for (const v of state.items) {
        for (const v2 of v.products) {
          if (state.cartIds.includes(v2.cartId)) {
            state.total = state.total + v2.price * v2.quantity
          }
        }
      }
    }
    const onDelete = () => {
      Toast.loading({
        forbidClick: true
      })
      post('/cart.delete', {
        cartIds: state.cartIds
      }).then(res => {
        Toast.clear()
        // state.items = state.items.filter(v => state.cartIds.indexOf(v.cartId) === -1)
        state.cartIds = []
        init()
      })
    }
    // const unique = (arr, val) => {
    //   const res = new Map()
    //   return arr.filter(item => !res.has(item[val]) && res.set(item[val], 1))
    // }
    const init = () => {
      Toast.loading({ duration: 0, forbidClick: true })
      post('/cart.list', {
        buyerId: state.buyerId,
        storeId: state.storeId
      }).then(res => {
        state.items = res.data.stores
        Toast.clear()
      })
    }
    watch(() => state.items, (to, previous) => {
      resetTotal()
    }, { deep: true })
    watch(() => state.cartIds, (to, previous) => {
      // state.total = 0
      // for (const item of state.products) {
      //   if (state.cartIds.indexOf(item.cartId) > -1) {
      //     state.total = state.total + item.productCurrentPrice * item.productQuantity
      //   }
      // }
      resetTotal()
    }, { deep: true })
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onSubmit,
      onDelete,
      onGoBack
    }
  }
}
</script>

<style scoped>
</style>
